import React, { useState } from "react";
import { useCloseSelectBox } from "hooks";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import Image from "next/image";

export default function Language() {
  const [openLanguage, setOpenLanguage] = useState(false);
  const [wrapperRef] = useCloseSelectBox(setOpenLanguage);
  const router = useRouter();
  const [cookie, setCookie] = useCookies(["NEXT_LOCALE"]);

  const changeLanguage = (e) => {
    setOpenLanguage(!openLanguage);
    // router.push(`${locale}${router.pathname}`, `${locale}${router.pathname}`, { locale });
    if (cookie.NEXT_LOCALE !== e) {
      setCookie("NEXT_LOCALE", e, { path: "/" });
    }
    let pageList = [
      {
        asPageURL: "/tr/",
        pageURL: "/"
      },
      {
        asPageURL: "/en/",
        pageURL: "/"
      },
      {
        asPageURL: "/en/sign-up",
        pageURL: "/signup"
      },
      {
        asPageURL: "/tr/kayit-ol",
        pageURL: "/signup"
      },

      //configuration
      {
        asPageURL: "/en/configuration",
        pageURL: "/configuration"
      },
      {
        asPageURL: "/tr/yapilandirma",
        pageURL: "/configuration"
      },

      //basket
      {
        asPageURL: "/en/basket",
        pageURL: "/basket"
      },
      {
        asPageURL: "/tr/sepet",
        pageURL: "/basket"
      },


      //domain
      {
        asPageURL: "/en/domains",
        pageURL: "/domains"
      },
      {
        asPageURL: "/tr/alanadi",
        pageURL: "/domains"
      },

      {
        asPageURL: "/en/search-domain",
        pageURL: "/domainsearch"
      },
      {
        asPageURL: "/tr/alanadi-sorgulama",
        pageURL: "/domainsearch"
      },

      {
        asPageURL: "/en/domain-transfer",
        pageURL: "/domaintransfer"
      },
      {
        asPageURL: "/tr/alanadi-transfer",
        pageURL: "/domaintransfer"
      },


      //web-hostings
      {
        asPageURL: "/en/web-hosting",
        pageURL: "/web-hosting"
      },
      {
        asPageURL: "/tr/web-hosting",
        pageURL: "/web-hosting"
      },

      {
        asPageURL: "/en/web-hosting/cpanel",
        pageURL: "/web-hosting/cpanel"
      },
      {
        asPageURL: "/tr/web-hosting/cpanel",
        pageURL: "/web-hosting/cpanel"
      },

      {
        asPageURL: "/en/corporate-web-hosting",
        pageURL: "/corporatewebhosting"
      },
      {
        asPageURL: "/tr/kurumsal-web-hosting",
        pageURL: "/corporatewebhosting"
      },

      //servers
      {
        asPageURL: "/en/servers",
        pageURL: "/servers"
      },
      {
        asPageURL: "/tr/sunucular",
        pageURL: "/servers"
      },

      {
        asPageURL: "/en/servers/virtual-servers",
        pageURL: "/servers/virtual-servers"
      },
      {
        asPageURL: "/tr/sunucular/sanal-sunucular",
        pageURL: "/servers/virtual-servers"
      },


      {
        asPageURL: "/en/servers/cloud/windows",
        pageURL: "/servers/cloud/windows"
      },
      {
        asPageURL: "/tr/sunucular/sanal/windows",
        pageURL: "/servers/cloud/windows"
      },

      {
        asPageURL: "/en/servers/cloud/linux",
        pageURL: "/servers/cloud/linux"
      },
      {
        asPageURL: "/tr/sunucular/sanal/linux",
        pageURL: "/servers/cloud/linux"
      },

      {
        asPageURL: "/en/servers/cloud-servers",
        pageURL: "/servers/cloud-servers"
      },
      {
        asPageURL: "/tr/sunucular/bulut",
        pageURL: "/servers/cloud-servers"
      },

      {
        asPageURL: "/en/servers/dedicated",
        pageURL: "/servers/dedicated"
      },
      {
        asPageURL: "/tr/sunucular/fiziksel",
        pageURL: "/servers/dedicated"
      },

      //E-MAİL

      {
        asPageURL: "/en/email",
        pageURL: "/email"
      },
      {
        asPageURL: "/tr/eposta",
        pageURL: "/email"
      },

      {
        asPageURL: "/en/email/e-mail",
        pageURL: "/email/e-mail"
      },
      {
        asPageURL: "/tr/eposta/kurumsal-mail",
        pageURL: "/email/e-mail"
      },

      {
        asPageURL: "/en/email/iys",
        pageURL: "/email/iys"
      },
      {
        asPageURL: "/tr/eposta/iys",
        pageURL: "/email/iys"
      },

      //Ekstra

      {
        asPageURL: "/en/extra",
        pageURL: "/extra"
      },
      {
        asPageURL: "/tr/ekstra",
        pageURL: "/extra"
      },


      {
        asPageURL: "/en/extra/ssl-certificates",
        pageURL: "/extra/ssl-certificates"
      },
      {
        asPageURL: "/tr/ekstra/ssl-sertifikalari",
        pageURL: "/extra/ssl-certificates"
      },

      {
        asPageURL: "/en/extra/firewall-security",
        pageURL: "/extra/firewall-and-security"
      },
      {
        asPageURL: "/tr/ekstra/firewall-guvenlik",
        pageURL: "/extra/firewall-and-security"
      },

      {
        asPageURL: "/en/extra/control-panels",
        pageURL: "/extra/control-panels"
      },
      {
        asPageURL: "/tr/ekstra/kontrol-panelleri",
        pageURL: "/extra/control-panels"
      },

      //corporate
      {
        asPageURL: "/en/corporate/about-us",
        pageURL: "/corporate/about-us"
      },
      {
        asPageURL: "/tr/kurumsal/hakkimizda",
        pageURL: "/corporate/about-us"
      },

      {
        asPageURL: "/en/corporate",
        pageURL: "/aboutusservices"
      },
      {
        asPageURL: "/tr/kurumsal",
        pageURL: "/aboutusservices"
      },

      {
        asPageURL: "/en/servers/co-location",
        pageURL: "/servers/co-location"
      },
      {
        asPageURL: "/tr/sunucular/barindirma",
        pageURL: "/servers/co-location"
      },

      {
        asPageURL: "/en/corporate/financial-and-commercial-information",
        pageURL: "/corporate/financial-and-commercial-information"
      },
      {
        asPageURL: "/tr/kurumsal/finansal-ve-ticari-bilgiler",
        pageURL: "/corporate/financial-and-commercial-information"
      },

      {
        asPageURL: "/en/corporate/infrastructure",
        pageURL: "/corporate/infrastructure"
      },
      {
        asPageURL: "/tr/kurumsal/alt-yapimiz",
        pageURL: "/corporate/infrastructure"
      },

      {
        asPageURL: "/en/corporate/contact",
        pageURL: "/corporate/contact"
      },
      {
        asPageURL: "/tr/kurumsal/iletisim",
        pageURL: "/corporate/contact"
      },
      {
        asPageURL: "/en/contracts",
        pageURL: "/contracts"
      },
      {
        asPageURL: "/tr/sozlesmeler",
        pageURL: "/contracts"
      },
      {
        asPageURL: "/en/contracts/user-agreement",
        pageURL: "/contracts/user-agreement"
      },
      {
        asPageURL: "/tr/sozlesmeler/kullanici-sozlesmesi",
        pageURL: "/contracts/user-agreement"
      },

      {
        asPageURL: "/en/contracts/refund-policy",
        pageURL: "/contracts/refund-policy"
      },
      {
        asPageURL: "/tr/sozlesmeler/iade-sartlari",
        pageURL: "/contracts/refund-policy"
      },
      
      {
        asPageURL: "/en/contracts/privacy-policy",
        pageURL: "/contracts/privacy-policy"
      },
      {
        asPageURL: "/tr/sozlesmeler/gizlilik-politikasi",
        pageURL: "/contracts/privacy-policy"
      },
      {
        asPageURL: "/en/contracts/general-term-of-use",
        pageURL: "/contracts/general-term-of-use"
      },
      {
        asPageURL: "/tr/sozlesmeler/genel-kullanim-kosullari",
        pageURL: "/contracts/general-term-of-use"
      },

     {
        asPageURL: "/en/contracts/information-security-policy",
        pageURL: "/contracts/information-security-policy"
      },
      {
        asPageURL: "/tr/sozlesmeler/bilgi-guvenligi-politikasi",
        pageURL: "/contracts/information-security-policy"
      },
   

    ];
    const pageToRedirect = pageList.find(x => x.pageURL.includes(router.pathname) && x.asPageURL.startsWith("/" + e));
    const activePage = pageList.filter(x => x.pageURL.includes(pageToRedirect.pageURL) && x.asPageURL !== pageToRedirect.asPageURL)[0];

    router.push(activePage.pageURL, pageToRedirect.asPageURL.startsWith("/tr") ? pageToRedirect.asPageURL.substr(3, pageToRedirect.asPageURL.length) : pageToRedirect.asPageURL, {
      locale: e
    });

  };

  return (
    <div className="inline-block relative text-left" ref={wrapperRef}>
      <div>
        <button type="button" onClick={() => setOpenLanguage(!openLanguage)}
          className="inline-flex z-50 justify-center items-center px-3 py-1 w-full h-10 text-sm font-medium text-gray-700 bg-white rounded-lg border-2 border-makdosSecondaryGray hover:bg-gray-50 focus:outline-none"
          id="options-menu-language" aria-haspopup="true" aria-expanded="true">

          {router.locale === "tr" ?
            <div className="flex items-center">
              <div className="mt-1 mr-1">
                <Image src="/langTR.png" alt="turkey" title="Turkey" width="20" height="13" />
              </div>
              TR
            </div>
            :
            <div className="flex items-center">
              <div className="mt-1 mr-1">
                <Image src="/langEN.png" alt="english" title="English" width="20" height="13" />
              </div>

              EN
            </div>
          }

          <svg className="-mr-1 ml-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd" />
          </svg>
        </button>
      </div>
      {openLanguage &&
        <div className="absolute left-0 z-50 mt-2 w-32 bg-white rounded-lg ring-opacity-5 shadow-lg origin-top-left">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button type="button" onClick={() => changeLanguage("tr")}
              className="flex justify-center items-center px-4 py-2 w-full text-sm font-medium text-gray-700 bg-white border-b-2 border-makdosSecondaryGray hover:bg-gray-50 focus:outline-none"
              id="options-menu-tr" aria-haspopup="true" aria-expanded="true">
              <div className="mt-1 mr-1">
                <Image src="/langTR.png" alt="lang-tr" title="TR" width="20" height="13" />
              </div>
              TR
            </button>
            <button type="button" onClick={() => changeLanguage("en")}
              className="inline-flex justify-center items-center px-4 py-2 w-full text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              id="options-menu-en" aria-haspopup="true" aria-expanded="true">
              <div className="mt-1 mr-1">
                <Image src="/langEN.png" alt="lang-en" title="EN"  width="20"  height="13" />
              </div>

              EN
            </button>
          </div>
        </div>
      }

    </div>
  );
}