import { useCloseSelectBox } from "hooks";
import React, { useEffect, useState } from "react";
import { useGlobalStore } from "store/GlobalStore";
import Loader from "react-loader-spinner";

function Currency() {
  const [openCurrency, setOpenCurrency] = useState(false);
  const [wrapperRef] = useCloseSelectBox(setOpenCurrency);
  const [currencyType, setCurrencyType] = useGlobalStore((state) => [state.currencyType, state.setCurrencyType]);

  const [currencyText, setCurrencyText] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const onSetCurrencyType = (value) => {
    setOpenCurrency(!openCurrency);
    setCurrencyType(value);
  };

  useEffect(() => {
    setCurrencyText(currencyType === "₺" ? " ₺ TRY" : (currencyType === "$" ? "$ USD" : "€ EUR"));
    setIsLoading(false);
  }, [currencyType]);

  return (
    <div className="inline-block relative text-left" ref={wrapperRef}>
      <div>
        <button type="button" onClick={() => setOpenCurrency(!openCurrency)}
          className="inline-flex z-50 justify-center items-center px-3 py-1 w-full h-10 text-sm font-medium text-gray-700 bg-white rounded-lg border-2 border-makdosSecondaryGray hover:bg-gray-50 focus:outline-none"
          id="options-menu-currency" aria-haspopup="true" aria-expanded="true">

          {isLoading ?
            <div className="flex flex-col justify-center items-center">
              <Loader
                type="Oval"
                color="#FFDD00"
                height={10}
                width={10}
                timeout={1000}
              />
            </div>
            :
             currencyText 

          }
          <svg className="-mr-1 ml-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd" />
          </svg>
        </button>
      </div>
      {openCurrency &&
        <div className="absolute left-0 z-50 mt-2 w-32 bg-white rounded-lg ring-opacity-5 shadow-lg origin-top-left">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button type="button" name="₺" onClick={(e) => onSetCurrencyType(e.target.name)}
              className="inline-flex justify-center items-center px-4 py-2 w-full text-sm font-medium text-gray-700 bg-white border-b-2 border-makdosSecondaryGray hover:bg-gray-50 focus:outline-none"
              id="options-menu1" aria-haspopup="true" aria-expanded="true">

              ₺ TRY
            </button>
            <button type="button" name="$" onClick={(e) => onSetCurrencyType(e.target.name)}
              className="inline-flex justify-center items-center px-4 py-2 w-full text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              id="options-menu2" aria-haspopup="true" aria-expanded="true">

              $ USD
            </button>
            <button type="button" name="€" onClick={(e) => onSetCurrencyType(e.target.name)}
              className="inline-flex justify-center items-center px-4 py-2 w-full text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              id="options-menu3" aria-haspopup="true" aria-expanded="true">

              € EUR
            </button>
          </div>
        </div>
      }

    </div>
  );
}

export default Currency;
