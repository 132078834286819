import React from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Image from "next/image";

function Footer() {
  const { t } = useTranslation(["common", "nav"]);

  return (

    <div className="border-t-2 Footer border-makdosSecondaryGray">
      <div className="container flex items-center py-6 mx-auto footerTop">

        <div className="items-center justify-between w-full px-4 lg:flex lg:px-0">
          <div className="logo">
            <Image src="/headerLogo.svg" alt="footer-logo" title="Footer Logo" width={186} height={50} />
          </div>

          <div className="grid grid-cols-3 gap-5 lg:gap-x-10">

            <div className="flex flex-col col-span-3 mt-2 lg:col-span-1 lg:mt-0">
              <span className="mb-1 text-xs text-textGray">{t('customerServices')}</span>
              <a className="text-lg font-bold" href="tel:+908503077744">(+90) 850 307 77 44</a>
            </div>

            <div className="flex flex-col col-span-3 mt-2 lg:col-span-1 lg:mt-0">
              <span className="mb-1 text-xs text-textGray">E-Mail</span>
              <a className="text-lg font-bold" href="mailto:info@makdos.com">info@makdos.com</a>
            </div>


            <div className="flex flex-col col-span-3 mt-2 lg:col-span-1 lg:mt-0">
              <span className="mb-1 text-xs text-textGray">{t('socialMedia')}</span>
              <div className="flex">
                <a className="mr-2" href="https://github.com/MakdosBilisim" target="__blank" title="Github">
                  <i className="text-2xl ri-github-fill"></i>
                </a>
                <a className="mr-2" href="https://www.linkedin.com/company/makdos/" target="__blank"
                  title="Linkedin">
                  <i className="text-2xl ri-linkedin-fill"></i>

                </a>
                <a className="mr-2" href="https://www.instagram.com/makdos.cloud/" target="__blank" title="İnstagram">
                  <i className="text-2xl ri-instagram-fill"></i>

                </a>
                <a className="mr-2" href="https://www.youtube.com/channel/UChJNsK-9r-dDkgBP8FHxwMA" target="__blank"
                  title="Youtube">
                  <i className="text-2xl ri-youtube-fill"></i>

                </a>
                <a className="mr-2" href="https://twitter.com/MakdosCloud" target="__blank" title="Twitter">
                  <i className="text-xl ri-twitter-x-fill"></i>

                </a>
                {/* <a className="" href="https://www.facebook.com/makdostech/" target="__blank" title="Facebook">
                  <i className="text-2xl ri-facebook-circle-fill"></i>

                </a> */}
              </div>
            </div>
          </div>

        </div>
      </div>
      <hr className="text-makdosSecondaryGray"></hr>
      <div className="container items-center px-4 py-6 mx-auto mb-3 footerContent sm:px-6">
        <div className="grid grid-cols-12">
          <div className="flex flex-col col-span-6 lg:col-span-2">
            <p className="mt-5 mb-2">{t("common:domainNameServices")}</p>
            <Link href="/domainsearch" as={t("nav:domainfind")}>
              <a className="mb-2 text-sm text-textGray hover:text-black">{t("common:domainNameSearch")}</a>
            </Link>
            <Link href="/domaintransfer" as={t("nav:domaintransfer")}>
              <a className="mb-2 text-sm text-textGray hover:text-black" >{t("common:domainTransfer")}</a>
            </Link>
          </div>
          <div className="flex flex-col col-span-6 lg:col-span-2">
            <p className="mt-5 mb-2">Web Hosting</p>
            <Link href="/web-hosting/cpanel" as={t("nav:personalwebhosting")}>
              <a className="mb-2 text-sm text-textGray hover:text-black" >Web
                Hosting</a>
            </Link>
            {/* <Link href='/corporatewebhosting' as={t('nav:corporatewebhosting')}>
              <a className="mb-2 text-sm text-textGray hover:text-black" href={t('nav:corporatewebhosting')}>Kurumsal Web Hosting</a>
            </Link> */}
            {/* <Link href='/bayiwebhosting' as={t('nav:resellerwebhosting')}>
              <a className="mb-2 text-sm text-textGray hover:text-black" href={t('nav:resellerwebhosting')}>Bayi Web Hosting</a>
            </Link> */}
            <a href="#" className="mb-2 text-sm text-textGray hover:text-black">{t("common:resellerWebHosting")}</a>
          </div>
          <div className="flex flex-col col-span-6 lg:col-span-2">
            <p className="mt-5 mb-2">{t("common:serverServices")}</p>

            <Link href="/servers/cloud/virtual-servers" as={t("nav:virtualServers")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:virtualServers")}</a>
            </Link>
            <Link href="/servers/cloud" as={t("nav:cloudservers")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:cloudServers")}</a>
            </Link>
            <Link href="/servers/dedicated" as={t("nav:dedicatedservers")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:dedicatedServers")}</a>
            </Link>
            <Link href="/servers/co-location" as={t("nav:colocation")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:co-location")}</a>
            </Link>
          </div>
          <div className="flex flex-col col-span-6 lg:col-span-2">
            <p className="mt-5 mb-2">{t("common:extra")}</p>
            <Link href="/extra/control-panels" as={t("nav:controlpanel")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:controlPanelLicance")}</a>
            </Link>
            <Link href="/extra/ssl-certificates" as={t("nav:sslcertificates")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:SSLCertificate")}</a>
            </Link>
            <Link href="/extra/firewall-and-security" as={t("nav:firewallandsecurity")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:firewallAndSecurity")}</a>
            </Link>
            {/* <Link href='/corporateemail' as={t('nav:corporateemail')}>
              <a className="mb-2 text-sm text-textGray hover:text-black" href={t('nav:corporateemail')}>Kurumsal E-Posta</a>
            </Link> */}

          </div>
          <div className="flex flex-col col-span-6 lg:col-span-2">
            <p className="mt-5 mb-2">{t("common:contracts")}</p>
            <Link href="/contracts/user-agreement" as={t("nav:userAgreement")}>
              <a className="mb-2 text-sm text-textGray hover:text-black" >{t("common:userAgreement")}</a>
            </Link>

            <Link href="/contracts/privacy-policy" as={t("nav:privacyPolicy")}>
              <a className="mb-2 text-sm text-textGray hover:text-black">{t("common:privacyPolicy")}</a>
            </Link>
            <Link href="/contracts/refund-policy" as={t("nav:refundPolicy")}>
              <a className="mb-2 text-sm text-textGray hover:text-black" >{t("common:refundPolicy")}</a>
            </Link>
            <Link href="/contracts/general-term-of-use" as={t("nav:termsOfUse")}>
              <a className="mb-2 text-sm text-textGray hover:text-black" >{t("common:termsOfUse")}</a>
            </Link>
            <Link href="/contracts/information-security-policy" as={t("nav:informationSecurityPolicy")}>
              <a className="mb-2 text-sm text-textGray hover:text-black" >{t("common:informationSecurityPolicy")}</a>
            </Link>
          </div>
          <div className="flex flex-col col-span-6 lg:col-span-2">
            <p className="mt-5 mb-2">{t("common:corporate")}</p>
            <Link href="/corporate/about-us" as={t("nav:aboutus")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:aboutUs")}</a>
            </Link>
            <Link href="/corporate/financial-and-commercial-information" as={t("nav:financialinformation")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:financialInformation")}</a>
            </Link>
            <Link href="/corporate/infrastructure" as={t("nav:ourinfrastructure")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:infrastructure")}</a>
            </Link>
            <Link href="/corporate/contact" as={t("nav:contactus")}>
              <a className="mb-2 text-sm text-textGray hover:text-black"
              >{t("common:contact")}</a>
            </Link>
            <div className="flex items-center space-x-2">
              <a href="/tr/">
                <Image src="/langTR.png" alt="turkey" title="Turkey" width="20" height="13" />
              </a>
              <a href="/en/">
                <Image src="/langEN.png" alt="english" title="English" width="20" height="13" />
              </a>
            </div>

          </div>
        </div>
      </div>

      <div className="items-center px-4 mx-auto text-center footerBottom sm:px-6 bg-makdosSecondaryGray">
        <p className="py-3 text-xs text-textGray">Makdos Bilişim Teknolojileri 2015 -
          2025 {t("common:allRightsReserved")}</p>
      </div>

    </div>


  );
}

export default Footer;
