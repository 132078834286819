import LiveChatTextBox from "./LiveChatTextBox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { checkVisitorInfo, createLiveChat, getCountry, sendMessage } from "services/allServices";
import * as ls from "local-storage";
import Loader from "react-loader-spinner";
import fernet from "fernet";
import { alertToast } from "../Toast/AllToast";
import PhoneSelectBox from "../FormElements/PhoneSelectBox";
import { useConfigureSelectBoxItem, useWindowSize } from "hooks";
import SimpleTextBox from "../FormElements/SimpleTextBox";
import { useTranslation } from "next-i18next";
import moment from "moment";
import useWebSocket from "react-use-websocket";
import { useDebounce } from "react-use";

const schema = yup.object().shape({
  name_surname: yup.string(),
  email: yup.string(),
  message: yup.string()
});

function StartChat({ fullData, height, deviceWidth, deviceHeight, openChat, data, openWebSocket, socketState, openFullScreen, webSocket, messagesEndRef, bottomScroll }) {
  const { t } = useTranslation(["common"]);

  const { register, handleSubmit, errors, reset, watch } = useForm({
    resolver: yupResolver(schema)
  });

  const [step, setStep] = useState(2);
  const [phoneList, setPhoneList] = useConfigureSelectBoxItem()
  const [selectedPhone, setSelectedPhone] = useState();
  const [phone, setPhone] = useState("");
  const { width } = useWindowSize();

  const {
    lastJsonMessage: writingMessage,
    sendJsonMessage: sendWritingMessage,
    getWebSocket: getWritingWebSocket
  } = useWebSocket(
    process.env.NEXT_PUBLIC_CHAT_WS_URL + "/ws/chat/writing?vt=" + ls.get("l_c_i_d"),
    {
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      reconnectInterval: 2000
    },
    step === 3
  );

  const stepMinHeight = {
    1: "min-h-[110px]",
    2: "min-h-[350px]",
    3: "min-h-[400px]"
  };

  function WsSend(message, clear) {
    sendWritingMessage(
      {
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        message: clear ? "" : message,
      }
    );
  }

  var [] = useDebounce(
    async () => {
      WsSend(watch("message"), false)
    },
    500,
    [watch("message")]
  );


  // const messageSendScroll = () => {
  //   if (messagesEndRef) {
  //     messagesEndRef.current.addEventListener("DOMNodeInserted", (event) => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //     });
  //   }
  // };

  const onSubmit = (data) => {
    if (step === 2) {
      var secret = new fernet.Secret("YmVya2ViZXJrZWJlcmtlYmVya2ViZXJrZWJlcmtlYmU=");
      var token = "";
      token = new fernet.Token({
        secret: secret
      });

      if (!data.email) return alertToast(t("error"), t("common:emailError"));
      if (!phone) return alertToast(t("error"), t("common:phoneError"));
      // if (phone.length < 13) return alertToast(t("error"), t("common:phoneError"));

      let today = moment().format("DD-MM-YYYY HH:mm");
      let randomNum = Math.floor(Math.random() * 1000000000000).toString().padStart(12, '0');
      let unique_key = token.encode(randomNum + "_|_" + data.name_surname + "_|_" + data.email + "_|_" + (phone ? ("+" + selectedPhone.id + phone) : 0) + "_|_" + (width < 720 ? "MOBILE" : "WEB") + "_|_" + today + "_|_" + "visitor");
      let configure = {
        vt: ls.get("l_c_i_d") ? ls.get("l_c_i_d") : unique_key,
      };
      checkVisitorInfo(configure).then((res) => {
        if (res?.vt) {
          ls.set("l_c_i_d", res.vt);
          openWebSocket(res.vt);
          setStep(3);
        } else {
          localStorage.setItem("l_c_i_d", unique_key);
          let configure = {
            contents: {
              chat_id: res.chat_id,
              vt: unique_key,
              full_path: window.location.href
            }
          };
          setStep(3);
          if (ls.get("l_c_i_d")) {
            openWebSocket()
          }
          // createLiveChat(configure).then((res) => {
          //   if (res.status !== "active_chat_exist") {
          //     localStorage.setItem("l_c_c_i_d", JSON.stringify(res.record.contents.chat_id));
          //     openWebSocket();

          //   } else {
          //     alertToast(t("error"), t("liveChatActiveChatError"));
          //   }

          // }).catch((err) => {
          //   console.log(err, "err create live chat")
          //   setStep(1)
          // })
        }
      });


    } else if (step === 3) {
      if (data?.message?.length < 5) { return alertToast(t("error"), t("liveChatMustCharactersError")) }
      if (!data?.message) { return alertToast(t("error"), t("liveChatMustCharactersError")) }
      let configure = {
        // unique_id: ls.get("l_c_i_d") || "",
        // chat_id: ls.get("l_c_c_i_d") || "",
        message: data.message,
        full_path: window.location.href.replace("https://makdos.com", ""),
        // full_path: window.location.href,
        // is_visitor: "visitor",
      };
      try {
        webSocket.send(JSON.stringify(configure));
        reset();
      }
      catch {
        alertToast(t("error"), "Something went wrong");
      }
      // sendMessage(configure, ls.get("l_c_i_d"), window.location.href).then((res) => {
      //   if (res?.status === "chat_closed") {
      //     ls.remove("l_c_i_d");
      //     ls.remove("l_c_c_i_d");
      //     setStep(1)
      //   }
      //   else {
      //     reset();
      //     messageSendScroll();
      //   }
      // });
    }

  };

  const onGetCountry = async () => {
    let countries = [];
    countries = await getCountry();
    let countryNamesArr = [];
    countries?.map((item) => {
      countryNamesArr.push(item.country_name);
    });
    setPhoneList(countries, "phone_code", "country_name");
  };

  useEffect(() => {
    phoneList.map(item => {
      if (item.id === "90") {
        setSelectedPhone(item);
      }
    });
  }, [phoneList]);


  useEffect(() => {
    if (ls.get("l_c_i_d")) {
      setStep(3);
    }
  }, []);

  useEffect(() => {
    onGetCountry()
  }, [])

  useEffect(() => {
    return () => {
      if (getWritingWebSocket()) {
        getWritingWebSocket().close();
      }
    }
  }, [])

  useEffect(() => {
    if (fullData && messagesEndRef.current) {
      bottomScroll()
    }
  }, [fullData, messagesEndRef.current])

  return (
    <div className=""  >
      <form onSubmit={handleSubmit(onSubmit)} >
        <div ref={messagesEndRef}
          className={`w-full px-6 py-4 bg-white min-w-10/12 lg:min-w-[400px]  overflow-y-auto customScroll flex ${deviceWidth < 720 ? "" : stepMinHeight[step]}`}
          style={deviceWidth < 720 ? (openFullScreen ? { height: deviceHeight - 280 } : { height: "350px" }) : (step === 2 ? { height: openChat ? height : 0, overflow: "initial" } : { height: openChat ? height : 0 })}>
          {step === 1 ?
            <div className="flex flex-col space-y-4">
              <p className="text-[#0F172A] font-semibold text-2xl">Konuşma Başlatın</p>
              <p className="text-[#475569]">Size yardım etmek için heyacanlıyız. <br /> Hemen konuşma başlatabilirsiniz.
              </p>
            </div>
            :
            <></>
          }
          {step === 2 ?
            <div className="flex flex-col pb-2 space-y-2 lg:space-y-4 lg:pb-4"   >
              <p className="text-[#0F172A] font-semibold text-2xl">{t("liveChatIntroduceYourself")}</p>
              <p className="text-[#475569]">{t("liveChatNeedInfo")}</p>
              <p className="text-[#475569] font-semibold">{t("liveChatNeedInfo2")}</p>
              <LiveChatTextBox
                id="name_surname"
                name="name_surname"
                innerRef={register}
                placeholder={t("nameSurname")}
                icon={<i className="text-2xl ri-user-3-line text-slate-400" />}
              />
              <LiveChatTextBox
                id="email"
                name="email"
                innerRef={register}
                placeholder={t("emailAddress")}
                icon={<i className="text-2xl ri-mail-line text-slate-400" />}
              />
              <div className="flex space-x-2">
                <div className="mt-5 lg:mt-0">
                  {selectedPhone &&
                    <PhoneSelectBox data={phoneList} selectedItem={selectedPhone}
                      setSelectedItem={(v) => setSelectedPhone(v)} labelName={t("common:code")} />
                  }
                </div>
                <div className="w-3/4">
                  <SimpleTextBox value={phone} onChange={(v) => { v?.length < 13 && setPhone(v?.replaceAll(/\D/g, '')) }} labelName={t("common:phoneNumber")}
                    inputName="phone" placeholder="55555555555" />
                </div>

              </div>
            </div>
            :
            <></>
          }
          {step === 3 ?
            <div>
              {socketState === "close" && !data?.length > 0 ?
                <div className="flex items-center justify-center w-full">
                  <Loader
                    type="Oval"
                    color="#FFDD00"
                    height={60}
                    width={60}
                  />
                </div>
                :
                <div className="flex flex-col pb-4 space-y-4">
                  {data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <div key={index}
                          className={`flex space-x-2 ${item?.is_visitor ? "flex-row-reverse space-x-reverse" : ""} `}>
                          <div>
                            <div className="h-[40px] w-[40px]">
                              {!item?.is_visitor ?
                                <img src="/avatarTech.png" alt="admin" title="Admin" />
                                :
                                <img src="/avatarYellow.png" alt="customer" title="Customer" />
                              }
                            </div>
                          </div>
                          <div
                            className={`${!item?.is_visitor ? " bg-slate-100 text-slate-600" : "bg-[#0F172A] text-white"} px-2 py-2 rounded-lg `}>
                            <p className="text-xs">{moment(item?.message_time).format("DD-MM-YYYY HH:mm:ss")}</p>
                            <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: item?.message }} />
                          </div>
                        </div>
                      );
                    })}
                </div>
              }
            </div>
            :
            <></>
          }
          {step === 4 ?
            <div className="flex flex-col space-y-4">
              <p className="text-[#0F172A] font-semibold text-2xl">{t("liveChatIntroduceYourself")}</p>
              <p className="text-[#475569]">{t("liveChatNeedInfo")}</p>
              <p className="text-[#475569] font-semibold">{t("liveChatNeedInfo2")}</p>
              <LiveChatTextBox
                id="email"
                name="email"
                placeholder="E-posta Adresiniz"
                icon={<i className="text-2xl ri-mail-line text-slate-400" />}
              />
            </div>
            :
            <></>
          }
          {step === 5 ?
            <div className="flex flex-col space-y-4">
              <p className="text-2xl font-semibold text-makdosYellow">Size Ulaşalım</p>
              <p className="text-slate-600">{t("liveChatNeedInfo")}</p>
              <LiveChatTextBox
                id="namesurname"
                name="namesurname"
                placeholder="Ad Soyad"
                icon={<i className="text-2xl ri-user-3-line text-slate-400" />}
              />
              <LiveChatTextBox
                id="email"
                name="email"
                placeholder="E-posta Adresiniz"
                icon={<i className="text-2xl ri-mail-line text-slate-400" />}
              />
              <div className="flex">
                <div className="mt-5 lg:mt-0">
                  {selectedPhone &&
                    <PhoneSelectBox data={phoneList} selectedItem={selectedPhone}
                      setSelectedItem={(v) => setSelectedPhone(v)} labelName={t("common:code")} />
                  }
                </div>
                <div className="w-3/4">
                  <SimpleTextBox value={phone} onChange={(v) => setPhone(v?.replaceAll(/\D/g, ''))} labelName={t("common:phoneNumber")}
                    inputName="phone" placeholder="a555555" />
                </div>

              </div>
            </div>
            :
            <></>
          }

        </div>
        <div id="live-chat-input" className="flex items-center px-6 py-4 space-x-4 rounded-b-lg bg-slate-200">
          {step === 3 ?
            <LiveChatTextBox
              id="message"
              name="message"
              innerRef={register}
              placeholder={t("liveChatWriteMessage")}
              padding="py-4"
            />
            :
            <div className="w-full"></div>
          }

          <button type="submit">
            <div className="w-12 h-12 rounded-full bg-[#0F172A] flex items-center justify-center cursor-pointer">
              <i className="text-2xl text-makdosYellow ri-send-plane-2-fill" />
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}

export default StartChat;