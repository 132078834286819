import Image from "next/image";
import React from "react";

function HomeMakdosBlogCard({ data }) {
  return (
    <a href={"https://makdos.blog/" + data?.main_category_slug + "/" + data?.id + "/" + data?.slug}
       target="_blank"
       className="z-10 col-span-4 p-6 pt-10 text-left rounded-lg border-b-4 border-transparent shadow-lg transition-all duration-300 cursor-pointer blogCard lg:col-span-1 hover:shadow-2xl hover:border-makdosYellow hover:border-b-4">
      <div className="relative z-10 cardImage">
        {data.image_url !== null ?
          <Image className="w-full lg:w-[273px] lg:h-[169px] h-full object-cover" src={"https://img.makdos.blog" + data?.image_url}
               alt={data?.image_url_description} title={data?.image_url_title} width="273" height="169" />
          :
          <Image className="w-full lg:w-[273px] lg:h-[169px] h-full object-cover" src="/blogImage.png" alt="makdos-blog" title="Makdos Blog" width="273" height="169" />

        }
        <span
          className="absolute left-2 bottom-4 w-12 text-xs text-center bg-gray-900 text-textWhite lg:bottom-4 lg:left-2 md:bottom-4 md:left-2">{data?.main_category}</span>
        <span
          className="flex absolute right-2 bottom-4 w-14 text-xs text-center bg-gray-900 text-textWhite lg:bottom-4 lg:right-2 md:bottom-4 md:right-96">
          <svg className="mr-1" width="15" height="15" viewBox="0 0 15 15" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.6875 13.25C4.40822 13.25 1.75 10.5918 1.75 7.3125C1.75 4.03322 4.40822 1.375 7.6875 1.375C10.9668 1.375 13.625 4.03322 13.625 7.3125C13.625 10.5918 10.9668 13.25 7.6875 13.25ZM7.6875 12.0625C8.94728 12.0625 10.1555 11.5621 11.0463 10.6713C11.9371 9.78046 12.4375 8.57228 12.4375 7.3125C12.4375 6.05272 11.9371 4.84454 11.0463 3.95374C10.1555 3.06295 8.94728 2.5625 7.6875 2.5625C6.42772 2.5625 5.21954 3.06295 4.32874 3.95374C3.43795 4.84454 2.9375 6.05272 2.9375 7.3125C2.9375 8.57228 3.43795 9.78046 4.32874 10.6713C5.21954 11.5621 6.42772 12.0625 7.6875 12.0625V12.0625ZM8.28125 7.3125H10.6562V8.5H7.09375V4.34375H8.28125V7.3125Z"
              fill="white" />
          </svg>
          {data?.read_time} dk.</span>
      </div>

      <h3 className="my-4 text-lg font-semibold">{data?.title}</h3>
      <p className="mr-3 mb-8 text-sm leading-5 text-cardText">{data?.description}</p>
    </a>
  );
}

export default HomeMakdosBlogCard;
