function LiveChatTextBox({
                           id,
                           name,
                           innerRef,
                           type,
                           placeholder,
                           icon,
                           padding,
                           onKeyUp
                         }) {
  return (
    <div className="relative w-full">
      {icon ?
        <div className="absolute top-1 left-2">
          {icon}
        </div>
        :
        <></>
      }

      <input
        className={` ${icon ? "pl-10" : "pl-2"} font-medium  ${padding ? padding : "py-2"} w-full border rounded-md border-slate-200 text-slate-400 placeholder-slate-400`}
        id={id}
        name={name}
        ref={innerRef}
        type={type}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
      />
    </div>

  );
}

export default LiveChatTextBox;