import { useCallback, useEffect, useRef, useState } from "react";
import LiveChatStatus from "./LiveChatStatus";
import StartChat from "./StartChat";
import * as ls from "local-storage";
import { checkVisitorInfo, closeWebSocket, postRatingChat } from "services/allServices";
import { useGlobalStore } from "store/GlobalStore";
import fernet from "fernet";
import { useTranslation } from "next-i18next";
import { useWindowSize } from "hooks";
import ReactStars from "react-stars";
import Button from "../FormElements/Button";
import { useAuthStore } from "store/AuthStore";


function LiveChat() {

  const { t } = useTranslation(["common", "nav"]);
  const [isLoggin] = useAuthStore((state) => [
    state.isLoggin,
  ]);

  // const webSocket = "";
  let webSocketCheck = ""

  const [openChat, setOpenChat] = useState(false);
  const liveChatScreenRef = useRef(null);
  const windowSize = useWindowSize();
  const [height, setHeight] = useState(0);
  const [positions, setPositions] = useState(20);
  const [isOverflown, setIsOverflown] = useState(false);
  const [chatStatus, setChatStatus] = useState();
  const [messages, setMessages] = useState([]);
  const [webSocketData, setWebSocketData] = useState("");
  const [webSocketState, setWebSocketState] = useState("");
  const [step, setStep] = useState(0);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [fullData, setFullData] = useState(false);
  const [webSocket, setWebSocket] = useState(null);
  const [ratingChat, setRatingChat] = useState(false)
  const [rating, setRating] = useState(5)
  const [ratingSuccess, setRatingSuccess] = useState(false)

  const messagesEndRef = useRef(null);

  const handler = useCallback((mouseDownEvent) => {
    const startSize = height;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent) {
      let totalHeight = window.innerHeight;
      let componentHeight = document.getElementById("live-chat-makdos").offsetHeight;
      if (componentHeight > 300 && (componentHeight - 60) < totalHeight) {
        setHeight(startSize + (startPosition.y - mouseMoveEvent.pageY));
      } else {
        document.body.removeEventListener("mousemove", onMouseMove);
      }

    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp);

    return () => {
      document.body.removeEventListener("mousemove", onMouseMove);
    };
  }, [height]);

  const handlePosition = useCallback((mouseDownEvent) => {
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent) {
      let onScreenLeft = mouseDownEvent.view.screen.width - (mouseDownEvent.view.screen.width * 30 / 100);
      let onScreenRight = 20;
      if ((positions + startPosition.x - mouseMoveEvent.pageX > onScreenRight) && (positions + startPosition.x - mouseMoveEvent.pageX < onScreenLeft)) {
        setPositions((positions + startPosition.x - mouseMoveEvent.pageX));
      }
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });

    return () => {
      document.body.removeEventListener("mousemove", onMouseMove);
    };
  }, [positions, isOverflown]);

  const openWebSocket = (vt) => {
    setMessages([]);
    setFullData("");
    const letwebSocket = new WebSocket(`${process.env.NEXT_PUBLIC_CHAT_WS_URL}/ws${vt ? `?vt=${vt}` : ls.get("l_c_i_d") ? `?vt=${ls.get("l_c_i_d")}` : ""}`);
    setWebSocket(letwebSocket);
    letwebSocket.onopen = (e) => {
      setWebSocketState("open");
    };
    letwebSocket.onmessage = (e) => {
      setWebSocketState("open");
      if (JSON.parse(e.data)?.status === "service_stoped") {
        setOpenChat(!openChat);
        closeWebSocket(ls.get("l_c_i_d"));
        ls.set("l_c_i_d", "");
        if (letwebSocket) {
          letwebSocket.onclose = () => {
          };
          letwebSocket.close();
        }
      }
      else {
        setWebSocketData(JSON.parse(e.data));
      }
    };
    letwebSocket.onclose = () => {
      setWebSocketState("close");
      setWebSocket()
    };
    letwebSocket.onerror = () => {
      setWebSocketState("close")
      setWebSocket()
    };
  };

  const openWebSocketCheck = () => {
    if (!webSocketCheck) {
      webSocketCheck = new WebSocket(`${process.env.NEXT_PUBLIC_CHAT_WS_URL}/ws/check`);
      webSocketCheck.onopen = () => {
        setChatStatus("open")
        if (!webSocket) {
          if (ls.get("l_c_i_d")) {
            openWebSocket()
          }
        }
      };
      webSocketCheck.onmessage = (e) => {
        if (JSON.parse(e.data)?.message === "service_started") {
          setChatStatus("open")
        }
        else {
          setChatStatus("close")
          setTimeout(() => {
            openWebSocketCheck()
          }, 5000)
        }
      };
      webSocketCheck.onclose = () => {
        setChatStatus("close");
        setTimeout(() => {
          openWebSocketCheck()
        }, 5000)
      };
      webSocketCheck.onerror = () => {
        setChatStatus("close");
        setTimeout(() => {
          openWebSocketCheck()
        }, 5000)
      };
    }
  };

  const onRatingChat = () => {
    postRatingChat(ls.get("l_c_i_d"), rating).then((res) => {
      setRatingSuccess(true)
      closeWebSocket(ls.get("l_c_i_d"));
      setMessages([]);
      setFullData("");
      if (webSocket) {
        webSocket.onclose = () => {
        };
        webSocket.close();
      }
      ls.remove("l_c_i_d")
    }).catch(() => {
      ls.remove("l_c_i_d");
      closeWebSocket(ls.get("l_c_i_d"));
      setMessages([]);
      setFullData("");
      if (webSocket) {
        webSocket.onclose = () => {
        };
        webSocket.close();
      }
    })
  }

  const bottomScroll = () => {
    const scroll = messagesEndRef.current?.scrollHeight;
    messagesEndRef.current?.scroll({ top: scroll, behavior: "smooth" });
  };

  useEffect(() => {
    if (webSocketData) {
      if (webSocketData.message === "chat_closed") {
        setRatingChat(webSocketData)
      }
      if (webSocketData?.status === "chat_closed") {
        if (webSocketData) {
          setRatingChat(webSocketData)
        }
      } else {
        if (Array.isArray(webSocketData.message_records)) {
          setMessages(webSocketData.message_records);
        }
        else {
          if (webSocketData.message_records) {
            let allMessage = messages.length > 0 ? messages : [];
            allMessage.push(webSocketData.message_records);
            setMessages(allMessage);
          }
        }
        setFullData(webSocketData);
        // let audio = new Audio("/newChat.wav");
        // if (webSocketData && webSocketData?.message_records && !webSocketData?.message_records[webSocketData.message_records?.length - 1]?.is_visitor) {
        //   audio.play();
        // }
      }
    }
  }, [webSocketData]);

  useEffect(() => {
    if (ls.get("l_c_i_d")) {
      var secret = new fernet.Secret("YmVya2ViZXJrZWJlcmtlYmVya2ViZXJrZWJlcmtlYmU=");
      var token = "";
      token = new fernet.Token({
        secret: secret,
        token: ls.get("l_c_i_d"),
        ttl: 0
      });
      let tokenData = token.decode();
      let tokenDataArray = tokenData.split("_|_");
      let configure = {
        // email: tokenDataArray[2],
        // visitor_token: localStorage.getItem("l_c_i_d") || ""
        vt: localStorage.getItem("l_c_i_d") || ""
      };
      checkVisitorInfo(configure).then(async (res) => {
        if (res.last_chat_status === "closed") {
          ls.remove("l_c_i_d");
          ls.remove("l_c_c_i_d");
          await setOpenChat(true);
          await setOpenChat(false);
        } else {
          setOpenChat(true);
          if (ls.get("l_c_i_d")) {
            openWebSocket();
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (openChat && !webSocketCheck) {
      openWebSocketCheck()
    }
  }, [openChat])

  useEffect(() => {
    setInterval(() => {
      if (chatStatus === "close") {
        openWebSocketCheck()
      }
    }, 5000)

    return () => {
      clearInterval()
    }
  }, [chatStatus])

  return (
    <div id="live-chat-makdos" via="berke&utku" className="fixed flex flex-col space-y-3 bottom-5 lg:bottom-2 z-[999999] items-end" style={{ right: windowSize.width < 720 ? "0px" : `${positions}px` }}>
      <div ref={liveChatScreenRef} id="live-chat-screen"
        className={`${openChat ? `translate-y-0` : "h-2 translate-y-96 opacity-0"} flex lg:w-[400px] w-full ${isOverflown === "left" ? "justify-start" : "justify-end"} duration-500 flex-col rounded-lg shadow-xl transition-all  `}
      >
        {
          openChat ?
            <div className="relative customScroll">
              <button className="absolute top-0 w-full h-2 cursor-row-resize" onMouseDown={handler}></button>
              {/* <button className="absolute top-0 left-0 w-2 h-full cursor-col-resize" onMouseDown={handlerWidth}></button> */}
              <div
                className="flex items-center justify-between px-6 py-4 border-b border-black border-opacity-25 rounded-t-lg bg-makdosYellow">
                <p className="text-[#0F172A] font-semibold text-2xl">{t('welcome')} 👋</p>
                <div className="flex items-center space-x-2">
                  <i onClick={() => setOpenChat(!openChat)} className="cursor-pointer ri-subtract-fill ext-3xl" />

                  <i onClick={() => setOpenFullScreen(!openFullScreen)} className="block text-2xl cursor-pointer ri-fullscreen-line lg:hidden" />



                  <button className="hidden lg:block" type="button" onMouseDown={handlePosition}>
                    <i className="text-2xl opacity-25 cursor-move ri-drag-move-fill" />
                  </button>
                  {/* {liveChatNotification ?
                    <i className="text-2xl opacity-25 cursor-pointer ri-notification-4-line"
                      onClick={() => setLiveChatNotification(false)} />
                    :
                    <i className="text-2xl opacity-25 cursor-pointer ri-notification-off-line"
                      onClick={() => setLiveChatNotification(true)} />
                  } */}
                  <i className="text-3xl cursor-pointer ri-close-line" onClick={() => {
                    if (ratingChat) {
                      setRatingChat(false)
                      ls.remove("l_c_i_d");
                      closeWebSocket(ls.get("l_c_i_d"));
                      if (webSocket) {
                        webSocket.onclose = () => {
                        };
                        webSocket.close();
                      }
                    }
                    else {
                      if (ls.get("l_c_i_d")) {
                        setRatingChat(true)
                        setMessages([]);
                        setFullData("");
                      }
                      else {
                        setOpenChat(!openChat)
                      }
                    }
                  }} />
                </div>

              </div>
              <LiveChatStatus status={chatStatus === "close" ? "error" : "online"} />
              {ratingChat ?
                <div className="bg-white flex flex-col items-center justify-center rounded-b-md h-[300px] text-center space-y-2 py-2">
                  {ratingSuccess ?
                    <>
                      <p className="text-textGray">Puanladığınız için teşekkürler.</p>
                      <Button buttonText="Yeni Sohbet Aç" type="button" onClick={() => {
                        setRatingChat(false)
                        setRatingSuccess(false)
                      }} />
                    </>
                    :
                    <>
                      <ReactStars
                        count={5}
                        value={rating}
                        onChange={(v) => setRating(v)}
                        size={24}
                        color={"#999999"}
                        color2={"#ff7c30"}
                        half={false}
                      />
                      <Button buttonText="Puanla" type="button" onClick={() => onRatingChat()} />
                      <p className="text-textGray">Hizmetimizi beğendiyseniz puanlamayı unutmayın.</p>
                    </>
                  }

                  {rating === 5 &&
                    <>
                      {/* <p className="text-textGray">Google haritalarda yorum yaparak ve 5 yıldız vererek makdos.com' da geçerli 20 kredi kazabilirsiniz. Yorum yaptıktan sonra destek talebi üzerinden kredinizi talep edebilirsiniz.</p> */}
                      <p className="text-textGray">Firmamız hakkında güzel yorumlarınızı bekliyoruz.</p>
                      <div className="flex items-center space-x-2">
                        {!ratingChat?.makdos_status && !isLoggin &&
                          <a href="/tr/kayit-ol/" target="_blank" className="flex items-center space-x-2 text-orange-500">
                            <p>Makdos Kayıt Ol</p>
                            <i className="text-xl text-orange-500 ri-link" />
                          </a>
                        }
                        <a href="https://maps.app.goo.gl/wwzGgDYzxvSwXC8R8" target="_blank" className="flex items-center space-x-2 text-orange-500">
                          <p>Yorum Yap</p>
                          <i className="text-xl text-orange-500 ri-link" />
                        </a>
                      </div>

                    </>
                  }
                </div>
                :
                chatStatus === "open" ?
                  <StartChat fullData={fullData} height={height} openChat={openChat} data={messages} openWebSocket={openWebSocket}
                    socketState={webSocketState} upperStep={step} setUpperStep={setStep} deviceWidth={windowSize.width} deviceHeight={windowSize.height} openFullScreen={openFullScreen} webSocket={webSocket} messagesEndRef={messagesEndRef} bottomScroll={bottomScroll} />
                  :
                  <div className="bg-white flex flex-col items-center justify-center rounded-b-md h-[200px]">
                    <p className="font-semibold text-textGray">Şimdilik Canlı Destek hizmeti veremiyoruz.</p>
                    <p className="my-2 text-textGray">
                      <a className="mr-1 font-semibold" href="tel:+908503077744" target="_blank">0850 307 77 44</a>
                      numarasından bize 7/24 ulaşabilirsiniz.</p>
                    <p className="text-textGray">Lütfen daha sonra tekrar deneyin.</p>

                  </div>
              }

            </div>
            :
            <></>
        }

      </div>
      <button type="button" id="live-chat-makdos-button"
        className={`${isOverflown === "left" ? "justify-start" : "justify-end"} flex w-full cursor-pointer`}
        onClick={() => setOpenChat(!openChat)}>
        <div
          className="flex items-center justify-center px-2 py-2 space-x-2 rounded-full w-[170px] bg-makdosYellow shadow-xl hover:bg-slate-800 duration-200 text-[#0F172A] hover:text-makdosYellow">
          <p className="font-medium ">{t('liveChat')}</p>
          <i className="text-2xl font-medium ri-message-3-line" />
        </div>
      </button>
    </div>
  );
}

export default LiveChat;